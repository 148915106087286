import React, { useState } from 'react';

import { Flex } from '../../assets/styles/global';

import {
  Section,
  CustomButton,
  Title,
  Description,

  BarGrid,
  BarContainerVideo,
  BarContainerArea,
  TitleA,
  SubTitle,
  DescriptionA,
} from './carouselExames.styles';

import { ContactWAForm } from '../ContactForm/contactWAForm';
import { requestForm } from '../../helpers/requestForm';

import Slider from './slider';

//IMAGE 1000 X 400
import ExamesImg1 from '../../assets/image/exame1.webp';
import ExamesImg2 from '../../assets/image/exame2.jpg';
import ExamesImg3 from '../../assets/image/exame3.jpg';
import ExamesImg4 from '../../assets/image/exame4.jpg';
import ExamesImg5 from '../../assets/image/exame5.jpg';
import ExamesImg6 from '../../assets/image/exame6.jpg';

const defaultSlides = [
  {
    id: 1,
    title: "RAIO-X",
    image: ExamesImg1
  },
  {
    id: 2,
    title: "HEMOGRAMA",
    image: ExamesImg2
  },
  {
    id: 3,
    title: "ELETROENCEFALOGRAMA",
    image: ExamesImg3
  },
  {
    id: 4,
    title: "ELETROCARDIOGRAMA",
    image: ExamesImg4
  },
  {
    id: 5,
    title: "COLONOSCOPIA",
    image: ExamesImg5
  },
  {
    id: 6,
    title: "TOMOGRAFIA",
    image: ExamesImg6
  }
]

export default function CarouselExames( { data } ) {

  const [ showForm, setShowForm ] = useState( false );

  const callWhatsApp = () => {

    if ( data.data.slug === 'blumenau' ) {
      window.open( 'https://tintim.link/whatsapp/7598a614-f0c1-4df6-8b8c-cccf22a962e8/03565949-6a86-4dfd-8a9b-ad04e2a9dfbf', "_blank" );
      return
    }

    if ( data.data.slug !== 'mogidascruzes' ) {
      window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.` )
    }

  }

  function handleClick( params ) {
    setShowForm( false );
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    callWhatsApp();
  }

  return (
    <Section id="exames">
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} >
        <Title>EXAMES MAIS REALIZADOS</Title>
        <Description>Exames Laboratoriais e Exames de Imagem</Description>
      </Flex>

      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"row"} style={{ marginBottom: "30px" }}>
        <BarGrid>

          <BarContainerVideo>
            <Slider slides={defaultSlides} />
          </BarContainerVideo>

          <BarContainerArea>
            <TitleA>Resultados precisos para proporcionar um melhor diagnóstico para você!</TitleA>
            <SubTitle>As clínicas e laboratórios contam com tecnologia e segurança, tudo pra você cuidar bem da sua saúde.</SubTitle>
            <DescriptionA>Alguns dos principais exames que você pode agendar pela Solumedi são: Exames de Sangue, Raio-X, Exames Admissionais, Endoscopia, Ultrassonografia, Testes Ergométricos, Ecografias e Ecocardiogramas, Espirometria, Ultrassom para gestantes, Ressonância Magnética, Exames de Análises Clínicas e muitos outros.</DescriptionA>
            <CustomButton
              onClick={() => {
                if ( data.data.slug === 'mossoro' ) {
                  setShowForm( true )
                } else {
                  callWhatsApp()
                }
              }}
              color={"#2a4f72"}
              background={"#fdd460"}
              className='agendamento_via_whats'
              width={"70%"}>AGENDE AGORA</CustomButton>
          </BarContainerArea>

        </BarGrid>
      </Flex>

      {showForm && <ContactWAForm handleClick={handleClick} />}

    </Section>
  )
}
