import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Header } from '../../components/LpHeader/header';
import { Footer } from '../../components/LpFooter/footer';
import { FooterPersonality } from '../../components/LpFooter/footerPersonality';

import ModalForm from '../../components/ModalForm/modalForm';
import ModalFormSJC from '../../components/ModalFormSJC/modalFormSJC';
//import ModalFormCard from '../../components/ModalFormCard/modalForm';
import LpBannerA from '../../components/LpBannerA/lpBannerA';
import LpBannerAA from './LpBannerAA/lpBannerAA';
import LpBannerAB from './LpBannerAB/lpBannerAB';
import LpBannerNoName from './LpBannerAA/lpBannerNoName';
//import LpBannerBelem from './LpBannerBelem/lpBannerBelem';
import LpBannerSalvador from './LpBannerSalvador/lpBannerSalvador';
import LpBannerArapongas from './LpBannerArapongas/lpBannerArapongas';
import LpBannerFloripaGroup from './LpBannerFloripaGroup/lpBannerFloripaGroup';

import LpImage from '../../components/LpImage/lpImage';
import LpImageFull from '../../components/LpImageFull/lpImageFull';
import LpImages from '../../components/LpImages/lpImages';

//import LpCampaignOutubroJSC from '../../components/LpCampaignOutubroSJC/campaign';
//import LpCampaignNovembroJSC from '../../components/LpCampaignNovembroSJC/campaign';
//import LpCampaignNovembroLp from '../../components/LpCampaignNovembroLp/campaign';

//import SetembroAmarelo from '../../components/SetembroAmarelo/setembroAmarelo';
//import OutubroRosa from '../../components/OutubroRosa/outubroRosa';
import BannerCampaign from '../../components/BannerCampaign/bannerCampaign';

import LpCardPromotion from '../../components/LpCardPromotion/lpCardPromotion';
import LpCardPromotionSJC from '../../components/LpCardPromotionSJC/lpCardPromotionSJC';
import LpMapa from '../../components/LpMapa/lpMapa';
import AgendeBoard from '../../components/AgendeBoard/agendeBoard';
import Testimonials from '../../components/Testimonials/testimonials';
//import TestimonialsORIGIN from '../../components/TestimonialsORIGIN/testimonials';
import GridEspecialidades from '../../components/GridEspecialidades/gridEspecialidades';
import GridAddress from '../../components/LpGridAddress/gridAddress';
import LpPriceBoard from '../../components/LpPriceBoard/lpPriceBoard';
//import { LpPriceBoardCard } from '../../components/LpPriceBoardCard/lpPriceBoardCard';
import { LpPriceBoardGroupCard } from '../../components/LpPriceBoardGroupCard/lpPriceBoardGroupCard';
import CarouselExames from '../../components/CarouselExames/carouselExames';
import LpSpecialties from '../../components/LpSpecialties/lpSpecialties';

//import {AlertNotification} from '../../components/AlertNotification/alertNotification';

export default function ModelA( { data } ) {
  const { id } = useParams();

  const [ onLpBannerA, setOnLpBannerA ] = useState( false )
  const [ onLpBannerAA, setOnLpBannerAA ] = useState( true )
  const [ onLpBannerAB, setOnLpBannerAB ] = useState( false )
  const [ onLpBannerNoName, setOnLpBannerNoName ] = useState( false )
  const [ onLpBannerSalvador, setOnLpBannerSalvador ] = useState( false )
  const [ onLpBannerArapongas, setOnLpBannerArapongas ] = useState( false )
  const [ onLpBannerFloripaGroup, setOnLpBannerFloripaGroup ] = useState( false )

  const [ onLpImage, setOnLpImage ] = useState( false )
  const [ onLpImageFull, setOnLpImageFull ] = useState( false )
  const [ onLpImages, setOnLpImages ] = useState( false )

  const [ onLpCardPromotion, setLpCardPromotion ] = useState( false )
  const [ onLpCardPromotionSJC, setLpCardPromotionSJC ] = useState( false )
  const [ onMapa, setOnMapa ] = useState( true )

  const [ onGridAddress, setOnGridAddress ] = useState( false )

  const [ onGridEspecialidades, setOnGridEspecialidades ] = useState( false )
  const [ onTestimonials, setOnTestimonials ] = useState( true )

  const [ onLpPrice, setOnLpPrice ] = useState( false )
  const [ onLpPriceGroupCard, setOnLpPriceGroupCard ] = useState( false )
  const [ onLpSpecialties, setOnLpSpecialties ] = useState( false )

  const [ infoPrice, setInfoPrice ] = useState( "70" )
  const [ showFooterButton, setShowFooterButton ] = useState( false )

  const [ showFooterPersonality, setShowFooterPersonality ] = useState( false )

  const [ changeShow, setChangeShow ] = useState( false );
  const [ changeShowSJC, setChangeShowSJC ] = useState( false );

  //const [alertNotification, setAlertNotification] = useState(false)

  var infoTagLondrina = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KG6MSJ7B');`;

  var infoTag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MXMKSK9R');`;

  useEffect( () => {
    if ( id === 'londrina' || id === 'cambe' || id === 'curitiba-cajuru' ) {

      const $pixelScript = document.createElement( "script" );
      document.head.appendChild( $pixelScript );
      if ( id === 'londrina' || id === 'cambe' ) $pixelScript.innerHTML = infoTagLondrina;
      if ( id === 'curitiba-cajuru' ) $pixelScript.innerHTML = infoTag;


      const $pixelNoscript = document.createElement( "noscript" );
      document.body.appendChild( $pixelNoscript );

      const $iframeNoscript = document.createElement( "iframe" );

      $iframeNoscript.height = "0";
      $iframeNoscript.width = "0";
      $iframeNoscript.style = "display:none,visibility:hidden";

      if ( id === 'londrina' || id === 'cambe' ) $iframeNoscript.src = "https://www.googletagmanager.com/ns.html?id=GTM-KG6MSJ7B";
      if ( id === 'curitiba-cajuru' ) $iframeNoscript.src = "https://www.googletagmanager.com/ns.html?id=GTM-MXMKSK9R";

      $pixelNoscript.appendChild( $iframeNoscript )

      return () => {
        document.head.removeChild( $pixelScript );
      }
    }
  }, [] )

  useEffect( () => {
    if ( id === 'curitiba-centro' ) {
      const script = document.createElement( 'script' );
      script.type = 'text/javascript';
      script.id = 'script-pht-advert-6934';
      script.src = 'https://phonetrack-static.s3.sa-east-1.amazonaws.com/widget/243a5e41c3fa86871d3fc473f72adb1e.js';
      script.async = true;
      document.head.appendChild( script );

      return () => {
        document.head.removeChild( script );
      };
    }

    if ( id === 'mogidascruzes' ) {
      const script = document.createElement( 'script' );
      script.type = 'text/javascript';
      script.id = 'script-pht-advert-6938';
      script.src = 'https://phonetrack-static.s3.sa-east-1.amazonaws.com/widget/f151d7f1e331b1a95112c4dde2364698.js';
      script.async = true;
      document.head.appendChild( script );

      return () => {
        document.head.removeChild( script );
      };
    }
  }, [] );

  useEffect( () => {

    switch ( true ) {
      case id === "barreiras":
      case id === "maceio":
        setInfoPrice( "100" )
        setOnLpBannerAA( true )
        break;

      case id === "curitiba-boavista":
        setOnLpBannerAA( false )
        setOnLpBannerAB( true )
        setOnLpPrice( true )
        break;

      /* case id === "blumenau":
      case id === "paranagua":
      case id === "franciscobeltrao":
        // setInfoPrice( "100" )
        setOnLpBannerAA( false )
        setOnLpBannerAB( true )
        break; */

      case id === "ribeiraopreto":
        setInfoPrice( "100" )
        setOnLpPrice( true )
        break;

      case id === "presidenteprudente":
        setInfoPrice( "100" )
        setOnLpPrice( true )
        break;

      case id === "guarulhos":
      case id === "joinville":
        setInfoPrice( "80" )
        setOnLpSpecialties( true )
        break;

      case id === "salvador":
        setInfoPrice( "97" )
        setOnLpBannerAA( false )
        setOnLpBannerSalvador( true )
        setOnLpPrice( true )
        break;

      case id === "cachoeira-almirantetamandare":
      case id === "cachoeira-curitiba":
        setOnLpBannerAA( false )
        setOnLpBannerSalvador( true )
        setOnLpPrice( true )
        break;

      case id === "cachoeira":
        setOnLpPrice( true )
        break;

      case id === "saobentodosul":
        setInfoPrice( "65" )
        setOnLpBannerAA( false )
        setOnLpBannerSalvador( true )
        break;

      case id === "arapongas":
        setInfoPrice( "139" )
        setOnLpBannerAA( false )
        setOnLpBannerSalvador( true )
        setOnLpPrice( true )
        break;

      case id === "arapongas-exames":
        setOnLpBannerAA( false )
        setOnLpBannerArapongas( true )
        break;

      case id === "salvador-exames":
        setInfoPrice( "75" )
        break;

      case id === "saojosedoscampos":
      case id === "jacarei":
        //setOnCampaignSJC(true)
        setOnGridEspecialidades( true )
        /* setOnLpPrice( true ) */
        setOnLpPriceGroupCard( true )
        setShowFooterButton( true )
        //setOnLpImages(true)
        //setLpCardPromotionSJC(true)
        break;

      case id === "piracicaba":
      case id === "pontagrossa":
      case id === "curitiba-cajuru":
      case id === "curitiba-cajuru-exames":
      case id === "curitiba-fazendinha":
      case id === "florianopolis":
      case id === "palhoca":
      case id === "saojose":
      case id === "santos":
      case id === 'chapeco':
      case id === 'umuarama':
      case id === 'criciuma':
      case id === 'franciscobeltrao':
      case id === 'paranagua':
        setOnLpPrice( true )
        break;

      case id === "curitiba-centro":
      case id === "curitiba-centro-2":
      case id === "mogidascruzes":
        setOnLpPrice( true )
        setOnMapa( false )
        break;

      case id === "londrina":
        //setOnLpImage(true)
        //setOnCampaignNovLp(true)
        //setOnLpPrice(true)
        //setAlertNotification(true)
        setInfoPrice( "69" )
        // setOnLpImage( true )
        break;
      case id === "londrina-exames":
      case id === "apucarana-exames":

        setOnLpPrice( true )
        break;

      case id === "florianopolis-urologista":
      case id === "saojose-urologista":
      case id === "palhoca-urologista":
        setOnLpBannerAA( false )
        setOnLpBannerFloripaGroup( true )
        break;

      case id === "apucarana":
        //setOnLpImage(true)
        //setOnCampaignNovLp(true)
        setOnLpPrice( true )
        //setInfoPrice( "98" )
        break;

      case id === "cambe":
        //setOnCampaignNovLp(true)
        //setOnLpPrice(true)
        //setAlertNotification(true)
        setInfoPrice( "79" )
        break;

      case id === "ibipora":
        setInfoPrice( "69" )
        break;

      case id === "araucaria-prefeitura":
        setInfoPrice( "57" )
        setOnLpPrice( true )
        break;

      case id === "araucaria-centro":
        setInfoPrice( "50" )
        break;

      case id === "araucaria":
        setInfoPrice( "50" )
        break;

      case id === "santafelicidade":
        setInfoPrice( "56" )
        break;

      case id === "sorocaba":
        setInfoPrice( "60" )
        setOnLpPrice( true )
        break;

      //case id === "joinville":
      //  setOnLpImages(true)
      //break;

      case id === "belem":
        setLpCardPromotion( true )
        setOnMapa( false )
        setInfoPrice( "69" )
        break;

      case id === "colombo":
        setShowFooterPersonality( true )
        break;

      case id === "caraguatatuba":
        setInfoPrice( "60" )
        setOnLpPrice( true )
        break;

      /* case id === "mogidascruzes":
        setOnLpBannerAA(false)
        setOnLpBannerNoName(true)
        break; */

      default:
        setInfoPrice( "70" )
    }

  }, [ data ] )


  const setShow = () => {
    setChangeShow( !changeShow )
  }

  const setShowSJC = () => {
    setChangeShowSJC( !changeShowSJC )
  }

  return (
    <>

      {changeShow ?
        <>
          <ModalForm data={data} setShow={setShow} />
        </>
        : <></>}

      {changeShowSJC && <ModalFormSJC data={data} setShow={setShowSJC} />}

      <Header email={data.data.email_adm} />
      {/* <BannerCampaign /> */}
      {/* {alertNotification && <AlertNotification />} */}

      {
        onLpBannerA && < LpBannerA data={data}
          infoPrice={infoPrice}
        />}
      {
        onLpBannerAA && < LpBannerAA data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />}
      {
        onLpBannerAB && < LpBannerAB data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />}
      {
        onLpBannerNoName && < LpBannerNoName data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />}

      {onLpBannerSalvador &&
        <LpBannerSalvador
          data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />
      }

      {onLpBannerArapongas &&
        <LpBannerArapongas
          data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />
      }

      {onLpBannerFloripaGroup &&
        <LpBannerFloripaGroup
          data={data}
          infoPrice={infoPrice}
          setShow={setShow}
        />
      }

      {onLpImageFull && < LpImageFull data={data}
      />}


      {
        onLpImage && < LpImage data={data}
        />} {
        onLpImages && < LpImages data={data}
        />}

      {onLpCardPromotion && <LpCardPromotion data={data} setShow={setShow} />}

      {
        onMapa && < LpMapa data={data} setShow={setShow}
        />} {
        onGridAddress && < GridAddress data={data}
        />}

      {
        onLpPrice && < LpPriceBoard data={data}
        />}

      {
        onLpPriceGroupCard && < LpPriceBoardGroupCard data={data}
        />}

      {onLpSpecialties && <LpSpecialties data={data} />}

      <AgendeBoard data={data}
        infoPrice={infoPrice}
      />

      {onTestimonials && < Testimonials />} { /*       <TestimonialsORIGIN /> */}

      {onLpCardPromotionSJC && <LpCardPromotionSJC data={data} setShow={setShowSJC} />}

      {onGridEspecialidades && < GridEspecialidades />}

      <CarouselExames data={data} />

      {showFooterPersonality ?
        <FooterPersonality data={data} showFooterButton={showFooterButton} />
        :
        <Footer data={data} showFooterButton={showFooterButton} />
      }

    </>
  );
}